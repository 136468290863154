import React, { useState } from 'react';
import '../assets/css/landing.css'
import '../assets/css/main.css'
import logoImage from '../assets/images/logo_coffee.svg';
import arrowImage from '../assets/images/arrow-up-right.svg';
import axios from 'axios';

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false)
  const [appLink, setAppLink] = useState('');

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_BASE_URL}/generate`, {
      prompt
    }).then(({ data }) => {
      setAppLink(data.message);
      setShowModal(true);
    }).catch((e) => {
      setError(e.error || e.message || 'Error occurred. Please try again')
      setTimeout(() => {
        setError(null)
      }, 2000)
    }).finally(() => setLoading(false))
  };

  return (
    <div className="container">
      <div className="heading">
        <img src={logoImage} alt="Coffee Logo" />
        <div className="headingcontent">
          <h3>Simple apps at the speed of thought.</h3>
          <p style={{ textAlign: 'center' }}>
            Espresso allows you create simple, personal web apps
            that you can use for your own specific workflow
          </p>
        </div>
      </div>
      <div className="creator-form">
        <div className="form-heading">
          <h4>Generate your first app</h4>
          <p>
            For now, Espresso is best used for generating simple
            apps.
          </p>
        </div>
        <form className="form-details" onSubmit={handleSubmit}>
          <div className="textarea">
            <label htmlFor="description">Enter app description</label>
            <textarea
              onChange={(e) => setPrompt(e.target.value)}
              id="prompt"
              placeholder="A to-do list app"
              required
            />
          </div>
          <button type="submit" disabled={loading}>{loading ? 'Loading...' : 'Generate'}</button>
          {error && <span style={{ color: 'red', textAlign: 'center' }}>{error}</span>}
        </form>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Here's your app link</h3>
            <div className="link-container">
              <a href={appLink} target="_blank" rel="noopener noreferrer">App Link</a>
              <div className="linkbutton">
                <p>open</p>
                <a href={appLink} target="_blank" rel="noopener noreferrer">
                  <img src={arrowImage} alt="Open Arrow" />
                </a>
              </div>
            </div>
            <hr />
            <p>
              Create an Espresso account to save this app so you can
              launch it anytime you need it
            </p>
            <button onClick={() => { alert('Registration unavailable') }}>
              Create Account
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;